<template>
	<w-alert :outline="outline" :value="true" color="error" icon="error">{{ text }}</w-alert>
</template>

<script>
/**
 * @displayName w-error
 *
 * @since 0.3.2
 */
export default {
	name: 'WError',
	props: {
		text: {
			required: true,
			type: String
		},
		outline: {
			default: false,
			required: false,
			type: Boolean
		}
	}
}
</script>
